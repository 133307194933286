import React from "react";
import {useApolloClient} from "@apollo/client";

/**
 * Provides a callback to clear Apollo cached queries.
 * @param clearApolloStore
 */
export default function useClearApolloStore() {

  const clearApolloStore = () => {
    if (apolloClient) {
      setLoading(true);
      apolloClient.resetStore().finally(() => setLoading(false));
    }
  };

  const apolloClient = useApolloClient();
  const [loading, setLoading] = React.useState(false);

  return ({clearApolloStore, loading});
}
