import React from "react";

/**
 * Returns true if the component is mounted, false otherwise
 * @return {boolean} Mount state
 */
export default function useIsMounted() {

  // Component is mounted as soon as it is being constructed (do not put that in the useEffect, because the latter is
  // run after the first render of the caller, and the caller might need the mount status before the end of the first render)
  const isMountedRef = React.useRef(true);

  React.useEffect(() => {
    return () => (isMountedRef.current = false);
  }, []);

  return isMountedRef.current;
};
