import React from "react";

/**
 * Context to hold functions to call to know the loading status of a translation bundle for a given namespace and language.
 * The translations strings do not need to be part of the context, they are added to the i18n object.
 * @type {React.Context<null>}
 */
const TranslationContext = React.createContext(null);

export default TranslationContext;
