import React from "react";
import {IntercomProvider} from "react-use-intercom";

/**
 * Provide a default Intercom widget. Do it as high as possible in the app tree, so that it can be displayed even
 * if an error occurs down the tree. Configure the Intercom widget by calling IntercomWidgetSetup down the tree,
 * when language and user are ready.
 */
export default function IntercomWidgetProvider(props) {
  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_API_ID;
  const {children} = props;

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
      {children}
    </IntercomProvider>
  )
}
