import React from "react";
import LANGUAGES from "../../../constants/languages";
import {useTranslation} from "react-i18next";

export default function useLanguages() {

  const {i18n} = useTranslation();

  /**
   * Finds current language in the list of defined languages (LANGUAGES).
   * For each language of the resolving chain used by i18n, check if it is in the list of LANGUAGES. Stop as soon as one is
   * found, it means it is the more specific. LANGUAGES[0] will always be somewhere in the resolving chain
   * because it is the fallback language, but it might not be the more specific.
   * @return {string} Language code among those in LANGUAGES
   */
  const getCurrentLanguageCode = React.useCallback(() => {
    // If fallback language is 'en' and current language is 'fr-CA' then i18n.languages returns 'fr-CA, fr, en'
    // (the current language, then its more generic forms, then the fallback language)
    return i18n.languages.find((language) => Object.keys(LANGUAGES).includes(language));
  }, [i18n]);

  /**
   * Gets the language name of a language code, as defined in LANGUAGES
   * @param languageCode Language code to search for
   * @return {string} Language name in plain English, or French, etc.
   */
  const getLanguageName = React.useCallback((languageCode) => {
    return LANGUAGES[languageCode] ? LANGUAGES[languageCode] : languageCode;
  }, []);

  /**
   * Gets a list of languages available other than the current language. The possible set of languages is defined
   * in LANGUAGES.
   * @return {*} Array of languages (code: language code, name: language name)
   */
  const getOtherLanguages = React.useCallback(() => {
    const currentLanguageCode = getCurrentLanguageCode();
    return Object.keys(LANGUAGES).filter(key => key !== currentLanguageCode).map(key => ({
      code: key,
      name: LANGUAGES[key],
    }));
  }, [getCurrentLanguageCode]);

  const getAllLanguageCodes = React.useCallback(() => {
    return Object.keys(LANGUAGES);
  }, []);

  const getFallbackLanguage = React.useCallback(() => {
    return Object.keys(LANGUAGES)[0];
  }, []);

  /**
   * Return true if a language is covered by the translations of another one, based on the i18next language notation.
   * The covering language must be the same as the covered language, or more generic. Eg.:
   * 'fr-CA' is covered by translations for 'fr'
   * 'fr' is covered by translations for 'fr'
   * 'fr' is NOT covered by translations for 'fr-CA'
   * @type {function(*, *): boolean} True if covered language is covered by covering language
   */
  const isLanguageCoveredBy = React.useCallback((coveredLanguage, coveringLanguage) => {
    const lCoveredLanguage = coveredLanguage.toLowerCase();
    const lCoveringLanguage = coveringLanguage.toLowerCase();
    return lCoveredLanguage.startsWith(lCoveringLanguage);
  }, []);

  return ({
    getCurrentLanguageCode,
    getLanguageName,
    getOtherLanguages,
    getAllLanguageCodes,
    getFallbackLanguage,
    isLanguageCoveredBy
  });
}
