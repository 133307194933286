/**
 * Standard error type for errors coming from the GraphQL server. Errors have a name and a message.
 */
export default class ServerError extends Error {
  constructor (name, message) {
    super(message);
    this.name = name;

    // capturing the stack trace keeps the reference to the error class, but this is not available on all browsers
    if (typeof Error.captureStackTrace === "function")
      Error.captureStackTrace(this, this.constructor);
  }
}
