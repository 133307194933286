import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import useFirebase from "../infra-no-ui/firebase/useFirebase";

function SignInWithAuthProvider(props) {
  const { googleButtonLabel, facebookButtonLabel, target } = props;
  const firebase = useFirebase();

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to target after sign in is successful.
    signInSuccessUrl: target,
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: "google.com",
        fullLabel: googleButtonLabel,
      },
      {
        provider: "facebook.com",
        fullLabel: facebookButtonLabel,
      },
    ],
  };

  return (
    <div>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth} />
    </div>
  );
}

export default SignInWithAuthProvider;
