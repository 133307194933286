import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./ActionLoadingComponent.scss";

/**
 * Base component used by Action components. When not loading the action, display the children.
 * When loading after the component has been clicked, display a spinner.
 * The region occupied by the component is the same in both cases.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionLoadingComponent(props) {

  const {loading, children} = props;

  const newChildren = children ? children : null;

  const notLoadingContentVisibilityClassName = loading ? "hidden" : "visible";
  const loadingContentVisibilityClassName = loading ? "visible" : "hidden";

  return (
    <span className={"ActionLoadingComponent"}>
      <span className={"overlapRegion"}>
        <span className={"notLoadingContent " + notLoadingContentVisibilityClassName}>{newChildren}</span>
        <span className={"loadingContent " + loadingContentVisibilityClassName}><Spinner as="span" animation="border" role="status" size="sm"/></span>
      </span>
    </span>
  );
};
