import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";
import React from "react";

/**
 * Dynamically load translation files pertaining to the authentication-no-ui module.
 * @return {*}
 */
export default function useTranslationAuthenticationNoUi() {

  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(() => ({
    'en': import('./locales/en/authentication-no-ui.json'),
    'fr': import('./locales/fr/authentication-no-ui.json')
  }), []);

  return useModuleTranslation('authentication-no-ui', imports);
}
