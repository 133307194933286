import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Banner.scss";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";

const Banner = () => {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "Banner");

  return (
    <div className="Banner">
      <Row>
        <Col>
          <span className="BannerMsg">
            {t("authentication-ui:banner_msg")}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="SubBannerMsg">
            {t("authentication-ui:sub_banner_msg")}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
