import React from "react";
import CancelIcon from "@material-ui/icons/Clear";
import ConfirmIcon from "@material-ui/icons/Done";
import Button from "react-bootstrap/Button";
import "./InlineEditInputButtons.scss";

export default function InlineEditInputButtons(props) {
  const { disabled, size, onClick } = props;

  return (
    <div className="InlineEditInputButtons">
      <InlineEditConfirmButton size={size} disabled={disabled} />
      <InlineEditCancelButton size={size} onClick={onClick} />
    </div>
  );
}

function InlineEditConfirmButton(props) {
  const { disabled, size } = props;
  return (
    <span className="button-span-confirm">
      <Button size={size} variant="success" type={"submit"} disabled={disabled}>
        <ConfirmIcon fontSize="inherit" />
      </Button>
    </span>
  );
}

function InlineEditCancelButton(props) {
  const { onClick, size } = props;
  return (
    <span>
      <Button size={size} variant="danger" onClick={onClick}>
        <CancelIcon fontSize="inherit" />
      </Button>
    </span>
  );
}
