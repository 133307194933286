import useAuthUser from "./useAuthUser";
import isUserAuthorized from "./isUserAuthorized";

/**
 * Checks that the authenticated user has the permissions to perform an action.
 * @param action Action the user wants to perform
 * @param data Optional data when authorization check requires more info (such as ownership)
 * @return {boolean} True if authenticated user has permission to perform action
 */
export default function useAuthorization(action, data) {
  const authUser = useAuthUser();

  return isUserAuthorized(authUser, action, data);
}
