import {registerLocale, setDefaultLocale} from "react-datepicker";
import {enCA, frCA} from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import useLanguages from "../infra-no-ui/translation/useLanguages";

registerLocale('en', enCA);
registerLocale('fr', frCA);

export default function DatePickerLocaleProvider(props) {

  const {children} = props;

  const {getCurrentLanguageCode} = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();
  setDefaultLocale(currentLanguageCode);

  return children ? children : null;
}
