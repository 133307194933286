// Don't forget to add localized labels for every option
const EmploymentTypeEnum = {
  FULL_TIME: "FULL_TIME",
  PART_TIME: "PART_TIME",
  CONTRACTOR: "CONTRACTOR",
  TEMPORARY: "TEMPORARY",
  INTERN: "INTERN",
  VOLUNTEER: "VOLUNTEER",
  PER_DIEM: "PER_DIEM",
  OTHER: "OTHER",
};

export default EmploymentTypeEnum;

/**
 * Return the translation for a given enum value.
 * @param t Translation function
 * @param code Work mode enum value
 * @returns {*} Translation string
 */
export function getEmploymentTypeLabel(t, code) {
  // The method receives a t function and repeats "namespace:" in front of every translation key so that
  // the following keys are extracted when running scan-translations.js script (or "npm run scan-translations").
  // Without the t(), the keys wouldn't be recognized as translation keys.

  switch (code) {
    case EmploymentTypeEnum.FULL_TIME:
      return t("jobs:employment_type_fulltime_label");
    case EmploymentTypeEnum.PART_TIME:
      return t("jobs:employment_type_parttime_label");
    case EmploymentTypeEnum.CONTRACTOR:
      return t("jobs:employment_type_contractor_label");
    case EmploymentTypeEnum.TEMPORARY:
      return t("jobs:employment_type_temporary_label");
    case EmploymentTypeEnum.INTERN:
      return t("jobs:employment_type_intern_label");
    case EmploymentTypeEnum.VOLUNTEER:
      return t("jobs:employment_type_volunteer_label");
    case EmploymentTypeEnum.PER_DIEM:
      return t("jobs:employment_type_perdiem_label");
    case EmploymentTypeEnum.OTHER:
      return t("jobs:employment_type_other_label");
    default:
      return code;
  }
}
