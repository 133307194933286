import React from "react";
import { useController } from "react-hook-form";
import useTranslationCommon from "../useTranslationCommon";
import "./ValidatedInput.scss";
import { MAX_LENGTH_INPUT } from "../../../constants/ui";
import WysiwygEditComponent from "./WysiwygEditComponent";
import { getWysiwygContentLength, getWysiwygContent } from "./WysiwygEditor";
import StringUtils from "../../../utils/StringUtils";

export default function ValidatedInputWysiwyg(props) {
  const { t, loading: tLoading } = useTranslationCommon();
  const {
    name,
    control,
    defaultValue,
    maxLength = MAX_LENGTH_INPUT,
    required,
    editorRef,
    ...otherProps
  } = props;

  // List of custom validations (value is not textual, so we need to provide our own validations)
  const validations = {
    maxLength: (value) => getWysiwygContentLength(value) <= maxLength,
    required: (value) => !required || StringUtils.requiredRegexp().test(getWysiwygContent(value)),
  };

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    rules: { validate: validations },
    name,
    control,
    defaultValue,
  });

  let errorMessage = "";

  if (error && !tLoading) {
    switch (error.type) {
      case "maxLength":
        errorMessage = t("common:common_input_length_max");
        break;

      case "required":
        errorMessage = t("common:common_input_required");
        break;

      default:
        errorMessage = error.type;
    }
  }

  return (
    <span className="ValidatedInput">
      <WysiwygEditComponent
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        editorRef={editorRef}
        {...otherProps}
      />
      {errorMessage && <span className="errorMessage">{errorMessage}</span>}
    </span>
  );
}
