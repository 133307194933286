import React from "react";
import Form from "react-bootstrap/Form";
import StringUtils from "../../../utils/StringUtils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InlineEditBase from "./InlineEditBase";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents";
import ValidatedInputText from "./ValidatedInputText";
import { useValidatedInputForm } from "./useValidatedInputForm";
import "./InlineEditValidatedInputText.scss";
import InlineEditInputButtons from "./InlineEditInputButtons";

/**
 * Input box that display the value as read only until we click the Edit icon next to the label.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditValidatedInputText(props) {
  return (
    <InlineEditBase
      {...props}
      EditComponent={WithOnBlurValidatedInputTextEditComponent}
    />
  );
}

// Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
const WithOnBlurValidatedInputTextEditComponent = withOnBlur()(
  ValidatedInputTextEditComponent
);

const validatedInputName = "validatedInput";

function ValidatedInputTextEditComponent(props) {
  const {
    viewAs,
    placeholder,
    value,
    onSubmit,
    onCancel,
    maxLength,
    required,
    setBlurListener,
    unsetBlurListener,
  } = props;

  const focusRef = React.useRef();

  const {
    control,
    formState: { isValid },
    watch,
  } = useValidatedInputForm();

  const formatOutput = React.useCallback((output) => {
    return StringUtils.nullToEmpty(output).trim();
  }, []);

  // Validation is performed by react-hook-form but useInlineEditInputEvents require a validation function to know
  // if the submit button can be enabled. Mock a validation function based on react-hook-form's decision.
  const validate = React.useCallback(() => {
    return isValid ? "" : "invalid";
  }, [isValid]);

  // We use currentValue only at initialization (it returns the formatted initial value).
  // After that, we call changeValue everytime the user types a letter so that the hook can update its submit and cancel
  // events, but even if currentValue changes as well, we don't feed it to ValidatedInputText because it is managed
  // by react-hook-form inside ValidatedInputText.
  const { currentValue, submit, cancel, changeValue } =
    useInlineEditInputEvents(
      value,
      onSubmit,
      validate,
      onCancel,
      setBlurListener,
      unsetBlurListener,
      focusRef,
      null,
      formatOutput
    );

  const hasError = !isValid;

  // Submit button is clicked
  const onLocalSubmit = (event) => {
    event.preventDefault();
    submit();
  };

  // User types a letter in the input box. Inform the useInlineEditInputEvents hook.
  const rawValue = watch(validatedInputName);

  React.useEffect(() => {
    changeValue(rawValue);
  }, [rawValue, changeValue]);

  // Cancel button is clicked
  const onClickCancel = (event) => {
    event.preventDefault();
    cancel();
  };

  return (
    <Form
      onSubmit={onLocalSubmit}
      className={"ValidatedInputTextEditComponent"}
    >
      <Row className={"input-row"}>
        <Col className="input-col">
          <ValidatedInputText
            name={"validatedInput"}
            control={control}
            inputRef={focusRef}
            type="text"
            defaultValue={currentValue}
            placeholder={placeholder}
            size={viewAs === "h1" ? "lg" : "text"}
            maxLength={maxLength}
            required={required}
          />
        </Col>
      </Row>
      <Row>
        <Col className="validated-button-col">
          <InlineEditInputButtons
            size="sm"
            disabled={hasError}
            onClick={onClickCancel}
          />
        </Col>
      </Row>
    </Form>
  );
}
