import React from "react";
import * as ROUTES from "../../constants/routes";
import Image from "react-bootstrap/Image";
import logo from "../../img/spotterful-workplace-logo.svg";
import {Link} from "react-router-dom";
import "./MenuLogo.scss";

export default function MenuLogo(/*props*/) {

  return (
    <div className="MenuLogo">
      <Link to={ROUTES.HOME}>
        <Image src={logo} className="logo" />
      </Link>
    </div>
  );
};
