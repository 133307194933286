import React from "react";
import useLanguages from "../infra-no-ui/translation/useLanguages";

export default function Terms() {
  const {getCurrentLanguageCode} = useLanguages();

  // Content is not in translation strings because it's too long; it should go in a CMS instead
  switch (getCurrentLanguageCode()) {
    case 'fr' :
      return getFrenchTermsText();
    default:
      return getEnglishTermsText();
  }
}

const getFrenchTermsText = () => {
  return (
    <div>
      <p>
        Ces conditions générales décrivent les règles et règlements pour l'utilisation
        du site Web Spotterful, situé à l'adresse https://spotterful.com.
      </p>

      <p>
        En accédant à ce site Web, nous supposons que vous acceptez ces conditions.
        Ne continuez pas à utiliser Spotterful si vous n'acceptez pas
        toutes les conditions énoncées sur cette page.
      </p>

      <p>
        La terminologie suivante s'applique aux présentes Conditions Générales, Déclaration de Confidentialité
        et Avis de Non-Responsabilité et tous les Accords: "Client", "Vous" et
        "Votre" fait référence à vous, la personne qui se connecte sur ce site Web et conformément
        aux Conditions Générales de la Société. "La Société", "Nous-mêmes", "Nous" et
        "Notre" fait référence au Groupe Mouse At Work Inc. "Partie", "Parties" ou "Nous",
        désigne à la fois le Client et nous-mêmes. Toutes les conditions se réfèrent à l'offre,
        l'acceptation et la considération du paiement nécessaire pour entreprendre le
        processus de notre assistance au Client de la manière la plus appropriée
        dans le but exprès de répondre aux besoins du Client en matière de
        la fourniture des services déclarés de la Société, conformément à et
        sous réserve de la loi en vigueur au Québec, Canada. Toute utilisation de la terminologie
        ci-haut ou autres mots au singulier, au pluriel, en majuscules, et/ou
        il(s)/elle(s), sont considérés comme interchangeables et donc comme
        se référant au même terme.
      </p>

      <h2>Cookies</h2>
      <p>
        Nous utilisons des cookies. En accédant à Spotterful, vous avez accepté
        d'utiliser des cookies en accord avec notre Politique de Confidentialité.
      </p>

      <p>
        La plupart des sites Web interactifs utilisent des cookies pour nous permettre de récupérer les détails de
        chaque visite de l'utilisateur. Les cookies sont utilisés par notre site Web pour permettre la
        fonctionnalité de certaines zones afin de faciliter la visite des personnes visitant notre
        site Internet. Certains de nos partenaires affiliés/publicitaires peuvent également utiliser
        des cookies.
      </p>

      <h2>Licence</h2>
      <p>
        Sauf indication contraire, la Société et/ou ses concédants de licence
        sont propriétaires des droits de propriété intellectuelle pour tout le matériel sur Spotterful.
        Tous les droits de propriété intellectuelle sont réservés. Vous pouvez y accéder à partir de
        Spotterful pour votre usage personnel sous réserve des restrictions définies dans
        ces Conditions Générales.
      </p>

      <p>Vous ne devez pas:</p>
      <ul>
        <li>Republier le matériel de Spotterful</li>
        <li>Vendre, louer ou sous-licencier du matériel de Spotterful</li>
        <li>Reproduire, dupliquer ou copier du matériel de Spotterful</li>
        <li>Redistribuer le contenu de Spotterful</li>
        <li>Le présent accord commence à la date des présentes.</li>
      </ul>
      <p>
        Certaines parties de ce site Web offrent aux utilisateurs la possibilité de publier leur propre
        contenu ("Contenu d'Utilisateur") dans certaines zones du site Web.
        La Société ne filtre pas, ne modifie pas, ne publie ni ne révise les Contenus d'Utilisateur avant
        leur affichage sur le site. Les Contenus d'Utilisateur ne reflètent pas les points de vue et
        opinions de la Société, de ses agents et/ou de ses sociétés affiliées. Les Contenus d'Utilisateur reflètent
        les points de vue et opinions de la personne qui publie ses points de vue et opinions.
        Dans la mesure permise par les lois applicables, la Société ne doit pas être
        responsable des Contenus d'Utilisateur ou de toute responsabilité, dommages ou dépenses causés
        et/ou subis du fait de toute utilisation et/ou publication et/ou
        apparition des Contenus d'Utilisateur sur ce site.
      </p>

      <p>
        La Société se réserve le droit de surveiller tous les Contenus d'Utilisateur et de supprimer
        tout Contenu d'Utilisateur pouvant être considéré comme inapproprié, offensant, inexact ou en
        violation des Conditions Générales.
      </p>

      <p>Vous garantissez et déclarez que:</p>
      <ul>
        <li>
          Vous avez le droit de publier le Contenu d'Utilisateur sur notre site Web et d'avoir tous
          les licences et consentements nécessaires pour le faire;
        </li>
        <li>
          Vos Contenus d'Utilisateur n'enfreignent aucun droit de propriété intellectuelle, y compris
          sans s'y limiter le droit d'auteur, le brevet ou la marque de tout tiers;
        </li>
        <li>
          Vos Contenus d'Utilisateur ne contiennent aucun élément diffamatoire, calomnieux, offensant,
          indécent ou autrement illégal et qui constitue une intrusion de la vie privée
        </li>
        <li>
          Vos Contenus d'Utilisateur ne seront pas utilisés pour solliciter ou promouvoir des activités illégales
        </li>
        <li>
          Vous accordez par la présente à la Société une licence non exclusive pour utiliser,
          reproduire, éditer et autoriser des tiers à utiliser, reproduire et éditer tout
          Contenu d'Utilisateur de votre part, sous toutes formes, formats ou supports.
        </li>
      </ul>

      <h2>Avis de non-responsabilité</h2>
      <p>
        Dans la mesure maximale permise par les lois applicables, nous excluons de notre responsabilité toute
        déclaration, garantie et condition relatives à notre site Web et
        l'utilisation de ce site Web. Rien dans cette clause de non-responsabilité a pour effet de:
      </p>
      <ul>
        <li>
          limiter ou exclure notre ou votre responsabilité en cas de décès ou de blessures;
        </li>
        <li>
          limiter ou exclure notre ou votre responsabilité en cas de fraude ou de
          fausse déclaration;
        </li>
        <li>
          limiter l'une de nos ou vos responsabilités d'une manière qui n'est pas autorisée
          en vertu des lois applicables; ou
        </li>
        <li>
          exclure l'une de nos ou vos responsabilités qui ne peuvent être exclues en vertu
          des lois applicables.
        </li>
        <li>
          Les limitations et interdictions de responsabilité énoncées dans la présente Section et
          ailleurs dans cette clause de non-responsabilité: (a) sont soumises aux dispositions du
          paragraphe précédent; et (b) régissent toutes les responsabilités découlant de la
          clause de non-responsabilité, y compris les responsabilités découlant du contrat, de la responsabilité délictuelle et pour
          manquement à une obligation légale.
        </li>
      </ul>
      <p>
        Tant que le site Web et les informations et services sur le site Web
        sont fournis gratuitement, nous ne serons pas responsables de toute perte ou
        dommages de toute nature.
      </p>
    </div>
  );
}

const getEnglishTermsText = () => {
  return (
    <div>
      <p>
        These terms and conditions outline the rules and regulations for the use
        of Spotterful Website, located at https://spotterful.com.
      </p>

      <p>
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use Spotterful if you do not agree to
        take all of the terms and conditions stated on this page.
      </p>

      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: "Client", "You" and
        "Your" refers to you, the person log on this website and compliant to
        the Company's terms and conditions. "The Company", "Ourselves", "We",
        "Our" and "Us", refers to Mouse At Work Group Inc. "Party", "Parties", or "Us",
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client's needs in respect of
        provision of the Company's stated services, in accordance with and
        subject to, prevailing law of Quebec, Canada. Any use of the above
        terminology or other words in the singular, plural, capitalization
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same.
      </p>

      <h2>Cookies</h2>
      <p>
        We employ the use of cookies. By accessing Spotterful, you agreed to
        use cookies in agreement with our Privacy Policy.
      </p>

      <p>
        Most interactive websites use cookies to let us retrieve the user's
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>

      <h2>License</h2>
      <p>
        Unless otherwise stated, the Company and/or its licensors own the
        intellectual property rights for all material on Spotterful. All
        intellectual property rights are reserved. You may access this from
        Spotterful for your own personal use subjected to restrictions set in
        these terms and conditions.
      </p>

      <p>You must not:</p>
      <ul>
        <li>Republish material from Spotterful</li>
        <li>Sell, rent or sub-license material from Spotterful</li>
        <li>Reproduce, duplicate or copy material from Spotterful</li>
        <li>Redistribute content from Spotterful</li>
        <li>This Agreement shall begin on the date hereof.</li>
      </ul>
      <p>
        Parts of this website offer an opportunity for users to post their own
        contents ("User Contents") in certain areas of the website.
        The Company does not filter, edit, publish or review User Contents prior to
        their presence on the website. User Contents do not reflect the views and
        opinions of the Company, its agents and/or affiliates. User Contents reflect
        the views and opinions of the person who post their views and opinions.
        To the extent permitted by applicable laws, the Company shall not be
        liable for the User Contents or for any liability, damages or expenses caused
        and/or suffered as a result of any use of and/or posting of and/or
        appearance of the User Contents on this website.
      </p>

      <p>
        The Company reserves the right to monitor all User Contents and to remove
        any User Contents which can be considered inappropriate, offensive, inaccurate or causes
        breach of these Terms and Conditions.
      </p>

      <p>You warrant and represent that:</p>
      <ul>
        <li>
          You are entitled to post the User Contents on our website and have all
          necessary licenses and consents to do so;
        </li>
        <li>
          Your User Contents do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;
        </li>
        <li>
          Your User Contents do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of
          privacy
        </li>
        <li>
          Your User Contents will not be used to solicit or promote unlawful activities
        </li>
        <li>
          You hereby grant the Company a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your User Contents in any and all forms, formats or media.
        </li>
      </ul>

      <h2>Disclaimer</h2>
      <p>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </p>
      <ul>
        <li>
          limit or exclude our or your liability for death or personal injury;
        </li>
        <li>
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </li>
        <li>
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </li>
        <li>
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </li>
        <li>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </li>
      </ul>
      <p>
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </p>
    </div>
  );
}
