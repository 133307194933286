import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation";

const QUERY_SCOPE = "globalPublic";
const QUERY_NAME = "globalLog";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
        $payload: JSON!,
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          payload: $payload,
        )
      }
    }
  `;

export default function useMutationGlobalLog() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
