// Don't forget to add localized labels for every option
const JobWorkModeEnum = {
  REMOTE: "REMOTE",
  TEMPORARY: "TEMPORARY",
  HYBRID: "HYBRID",
  IN_PERSON: "IN_PERSON",
};

export default JobWorkModeEnum;

/**
 * Return the translation for a given enum value.
 * @param t Translation function
 * @param code Work mode enum value
 * @returns {*} Translation string
 */
export function getJobWorkModeLabel(t, code) {
  // The method receives a t function and repeats "namespace:" in front of every translation key so that
  // the following keys are extracted when running scan-translations.js script (or "npm run scan-translations").
  // Without the t(), the keys wouldn't be recognized as translation keys.

  switch (code) {
    case JobWorkModeEnum.REMOTE:
      return t("jobs:work_mode_remote_label");
    case JobWorkModeEnum.TEMPORARY:
      return t("jobs:work_mode_temporary_label");
    case JobWorkModeEnum.HYBRID:
      return t("jobs:work_mode_hybrid_label");
    case JobWorkModeEnum.IN_PERSON:
      return t("jobs:work_mode_in_person_label");
    default:
      return code;
  }
}
