import { gql } from "@apollo/client";
import useDeferredQuery from "../../common/data-fetching/useDeferredQuery";

const QUERY_SCOPE = "resume";
const QUERY_NAME = "resumeDownloadUrl";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id)
    }
  }
`;

export default function useLazyQueryResumeDownloadUrl() {
  return useDeferredQuery(QUERY, QUERY_SCOPE, QUERY_NAME);
}
