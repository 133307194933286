import React from "react";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import {useIntercom} from "react-use-intercom";
import usePreviousState from "../infra-no-ui/react-helpers/usePreviousState";
import useLanguages from "../infra-no-ui/translation/useLanguages";

/**
 * This component updates the Intercom widget. It assumes IntercomWidgetProvider is called higher in the tree
 * and that the widget is booted (which should be taken care of by IntercomWidgetProvider)
 */
export default function IntercomSetup() {
  const { authenticated, profile } = useAuthUser();
  const { boot, update, show, hardShutdown } = useIntercom();
  const previousAuthenticated = usePreviousState(authenticated);
  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguage = getCurrentLanguageCode();

  // When user signs in, update Intercom with the user info; when he/she signs out, clear session
  React.useEffect(() => {

    // Options that do not depend on authentication
    const options = {
      languageOverride: currentLanguage
    }

    // User signs out: clear all and start a new session
    if (!authenticated && previousAuthenticated) {
      // Shuts down the Intercom instance and make sure the Intercom cookies are removed
      hardShutdown();
      // Starts a new session with the current language but no information on the user
      boot(options); // Does not work?
    } else {
      // User is signed in: use its profile information
      if (profile) {
        options.name = profile["firstName"] + " " + profile["lastName"];
        options.email = profile["email"];
      }

      // Update running session, no matter if user is currently signed in or not
      update(options);
    }

  }, [boot, update, show, hardShutdown, previousAuthenticated, authenticated, profile, currentLanguage]);

  // Nothing to display
  return null;
}
