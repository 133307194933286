import React from "react";

/**
 * This component catches errors that prevent React from rendering children, thus resulting in a blank page.
 * Use the "component" prop to pass the content to display when an error is caught.
 * Use the "logger" prop to pass the name of the component to use if error logging is required
 * See https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  render() {
    const Logger = this.props.logger;

    if (this.state.error !== undefined) {
      // Fallback content
      // In development mode only, React displays it for a few seconds then displays the real error.
      // In production mode, React displays it as expected.
      // Also log the error using a functional component (easier than fitting our hooks in the current ErrorBoundary component)
      return (
        <>
          {this.props.logger && <Logger error={this.state.error}/>}
          {this.props.component}
        </>
      )
    }

    // Regular content
    return this.props.children;
  }
}
