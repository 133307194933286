import React from "react";
import {useTranslation} from "react-i18next";

// Execute constant initialization and injection in React, but without namespaces because we don't know which ones yet
import './i18n';
import TypeUtils from "../../../utils/TypeUtils";
import useIsMounted from "../react-helpers/useIsMounted";
import TranslationContext from "./TranslationContext";
import useLanguages from "./useLanguages";

/**
 * Calls the i18next initialization method as imported above. The component calls the useShowLoader prop until
 * i18n initialization is ready.
 *
 * The i18n initialization turns ready even if no translations are loaded. Translations are not loaded at initialization
 * time because we want to load them on demand, when a component needs its module translations.
 *
 * A module is any first level folder under src/components. A component from a module and that needs translations
 * must call the useTranslationMODULENAME hook defined in its module to trigger the loading of the translations for the module.
 * The useTranslationMODULENAME hook calls the useModuleTranslation hook the list of translations to import, eg.:
 * {
 *  'en': import('./locales/en/authentication-ui.json'),
 *  'fr': import('./locales/fr/authentication-ui.json')
 * }
 *
 * The useTranslationMODULENAME hooks returns a 't' function and a 'loading' state. The 'loading' state turns off
 * when an import suitable for the current language is loaded. Do not use the output of 't' until 'loading' turns off.
 * The useShowLoader hook can be used by the calling component to delay rendering until the translation is ready.
 *
 * We don't use the native Suspend capability of i18next, because it won't turn on when loading new translations
 * after a change of language.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function TranslationProvider(props) {

  const {useShowLoader} = props;
  const isMounted = useIsMounted();
  const [loading, setLoading] = React.useState(true);
  const {ready, i18n} = useTranslation();
  const [readyLanguages, setReadyLanguages] = React.useState({});
  const {getCurrentLanguageCode, getFallbackLanguage, isLanguageCoveredBy} = useLanguages();

  /**
   * Callback to be used by context consumers to add translations to the global i18n object.
   * @type {function(*=, *=): void}
   */
  const addTranslations = React.useCallback((namespace, promises) => {

    // Enclose logic in setReadyLanguages callback to make it atomic, because it depends on previous value (to copy it)
    setReadyLanguages(prevReadyLanguages => {

      // If no import promises are provided, return same state (will skip re-render)
      if (!promises || !TypeUtils.ensureObject(promises) || Object.keys(promises).length === 0) {
        return prevReadyLanguages;
      }

      // Also return same state (to avoid re-render) if same translation promises were already submitted
      if (Object.keys(promises).every(language => prevReadyLanguages[namespace] && prevReadyLanguages[namespace][language] !== undefined)) {
        return prevReadyLanguages;
      }

      // Make a copy of all affected levels, because we must not modify the previous state directly
      const newReadyLanguages = TypeUtils.shallowCopyObject(prevReadyLanguages);
      const newNamespace = TypeUtils.shallowCopyObject(newReadyLanguages[namespace]);
      newReadyLanguages[namespace] = newNamespace;

      // For each element (language, dynamic import promise) passed, add an entry in the 'readyLanguages' state variable.
      // For every language in a namespace, we want to keep track of its loading status:
      // if undefined, an import promise for this language has not been defined yet;
      // if false, an import promise for this language has been defined but it has not yet returned (the import is loading)
      // if true, the import promise has returned successfully

      for (const [language, promise] of Object.entries(promises)) {
        // Do something only if it is the first time we have a promise for that language
        if (newNamespace[language] === undefined) {
          // Translation for this language is not ready, the import is loading
          newNamespace[language] = false;

          // Define what to do when the promise resolves
          promise
            .then(translation => {
              // Add the newly imported translations to the i18n object for the given namespace and language
              i18n.addResourceBundle(language, namespace, translation);

              // Avoid warning when setting state on an unmounted component if promise is resolved late
              if (isMounted) {
                setReadyLanguages(prevReadyLanguages => {
                  // Set the namespace/language as ready because we have completed the import
                  const newNamespace = TypeUtils.shallowCopyObjectSetProp(prevReadyLanguages[namespace], language, true);
                  const newReadyLanguages = TypeUtils.shallowCopyObjectSetProp(prevReadyLanguages, namespace, newNamespace);
                  return newReadyLanguages;
                })
              }
            });
        }
      }
      return newReadyLanguages;
    })
  }, [isMounted, i18n]);

  /**
   * Callback to be called by context consumers to check whether the translation for the current language has finished loading.
   * First check for a translation for the exact language (eg. 'fr-CA' translations if current language is 'fr-CA'),
   * otherwise check for more generic translations (eg. 'fr' translations if current language is 'fr-CA').
   *
   * If there are no translations defined for the current language, even if they are more generic, then check for
   * the loading state of the fallback language (eg. 'en' translations if current language is 'fr-CA').
   *
   * @type {function(*): boolean} True if translation is ready, false if not defined or in loading state
   */
  const isTranslationLoaded = React.useCallback((namespace) => {

    // No translations for this namespace, not worth waiting, let i18n handle the missing translation
    if (!readyLanguages[namespace])
      return true;

    // If there are imports for the current language or a more generic form, return true if one is ready, or false if they
    // are all still loading. We want to return false even if a fallback translation in another language is ready, because we want to avoid
    // a flicker in the rendering if the fallback language is ready and then the right translation comes in.
    const currentLanguageCode = getCurrentLanguageCode();
    const suitableLanguages = Object.keys(readyLanguages[namespace]).filter(importedLanguage => isLanguageCoveredBy(currentLanguageCode, importedLanguage));

    if (suitableLanguages.length > 0) {
      return suitableLanguages.find(language => readyLanguages[namespace][language]) !== undefined;
    }

    // If there are no suitable languages, assume current language is the fallback language and return whether a suitable
    // translation for the fallback language is ready
    const fallbackLanguageCode = getFallbackLanguage();
    const fallbackVariations = Object.keys(readyLanguages[namespace]).filter(importedLanguage => isLanguageCoveredBy(fallbackLanguageCode, importedLanguage));

    if (fallbackVariations.length > 0) {
      return fallbackVariations.find(language => !readyLanguages[namespace][language]) !== undefined;
    }

    // No suitable language found nor fallback language found, let 18n handle the missing translation
    return true;
  }, [readyLanguages, getCurrentLanguageCode, isLanguageCoveredBy, getFallbackLanguage]);

  // The component is not ready until the i18n object is ready, even though there are no translations to load yet.
  React.useEffect(() => {
    if (ready) {
      setLoading(false);
    }
  }, [ready, i18n]);

  useShowLoader(loading, "TranslationProvider");

  // RENDER

  // Do not display children if i18n is not initialized yet (ready from useTranslation is false),
  // because usage of useTranslation in children will fail.
  if (loading || !props.children)
    return null;

  return (
    <TranslationContext.Provider value={{addTranslations, isTranslationLoaded}}>
      {props.children}
    </TranslationContext.Provider>
  );
};
