import { useQuery } from "@apollo/client";
import TypeUtils from "../../../utils/TypeUtils";
import QueryUtils from "./QueryUtils";

/**
 * Function similar to useLazyQuery from Apollo GraphQL, but its callback is a promise (it is not the case with the
 * callback returned by useLazyQuery). Beware that callback from this function takes variables as argument (because it is the
 * refetch function returned by useQuery), whereas callback from useLazyQuery takes options.
 * @param query Query
 * @param queryScope Query scope (first child of Query)
 * @param queryName Name of the query
 * @param options Options
 * @return {((function(*=): Promise<{readonly data?: *}>)|<any, >)[]}
 */
export default function useDeferredQuery(query, queryScope, queryName, options = {}) {
  // Force skip: true
  const augmentedOptions = TypeUtils.assign(options, { skip: true });

  const result = useQuery(query, augmentedOptions);

  // The callback is the refetch function, but we remove the query name from the fetched data
  const fetch = (variables) => {
    return result
      .refetch(variables)
      .then(({ data }) => QueryUtils.removeQueryScopeAndName(data, queryScope, queryName));
  };

  return {fetch, ...result};
}
