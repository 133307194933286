import React from "react";
import FirebaseContext from "./FirebaseContext";

/**
 * Holds a reference to the firebase connection object, as initialized at application startup
 * @return {null}
 */
export default function useFirebase() {
  return React.useContext(FirebaseContext);
}
