import React from "react";
import ProfileInlineEditInput from "./ProfileInlineEditInput";
import InlineEditValidatedInputText from "../../common/widgets/InlineEditValidatedInputText";

/**
 * Generic component for all profile editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProfileInlineEditInputText(props) {

  return <ProfileInlineEditInput InlineEditInputComponent={InlineEditValidatedInputText} {...props}/>
};
